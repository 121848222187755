import React from "react"
import { graphql } from "gatsby"
import { Seo } from "../components/seo"

const IndexPage = () => <div/>
export default IndexPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
        metaInfos {
          title
          description
          langId
          defaultSocialImg
        }
      }
    }

    allWpPost {
      nodes {
        title
        databaseId
        excerpt
        date
        categories {
          nodes {
            databaseId
          }
        }
        tags {
          nodes {
            databaseId
          }
        }
        featuredImage {
          node {
            sourceUrl
          }
        }
        autoren {
          nodes {
            name
          }
        }
        acfMeta {
          author
          authorDescription
          authorImage {
            node {
              sourceUrl
            }
          }
          bodyClass
          menuTitle
          menuCategory
          orderId
          originalUrl
          originalDate
          shortened
          photoEditor
          slug
          translator
          pageType
          hasDiscussion
          darkBackground
          dataVisualization
          titleVideo {
            node {
              mediaItemUrl
            }
          }
          tileImage {
            node {
              mediaDetails {
                sizes {
                  name
                  sourceUrl
                }
              }
              sourceUrl
            }
          }
          titleVideoPoster {
            node {
              mediaDetails {
                sizes {
                  name
                  sourceUrl
                }
              }
              sourceUrl
            }
          }
          published
          titleVideoSource
          beginWithBody
          themeColor
        }
        language {
          slug
        }
        translations {
          databaseId
        }
      }
    }
  }
`

export const Head = ({ data, pageContext }) => {
  const { siteMetadata } = data.site
  return <Seo metaData={siteMetadata} selected={pageContext} />
}